






















.status-summary-group-total-chart {
  .chart {
    width: 90%;
  }
  .chart-content {
    width: 100%;
    .legend {
      width: 100px;
    }
  }

  @media screen and (max-width: 1200px) {
    .chart {
      width: 100%;
    }
  }
}
